import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/users",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Userlist")),
  },

  {
    exact: true,
    path: "/edit-users",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserEdit")),
  },
  {
    exact: true,
    path: "/partnerDataEdit",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/component/partnerEdit")),
  },

  {
    exact: true,
    path: "/stakingMgmt",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StackingMgmt/StackingTab")
    ),
  },

  {
    exact: true,
    path: "/contract-sniffed",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Contract/ContractSniffer")
    ),
  },

  {
    exact: true,
    path: "/nft-list",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/User/ViewStake")),
  },
  {
    exact: true,
    path: "/stake",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/User/Stake")),
  },

  {
    exact: true,
    path: "/nft-detail",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/User/NftDetail")),
  },
  {
    exact: true,
    path: "/user-list",
    guard: true,

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/User/UserManagement")
    ),
  },

  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/Login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/logo",
    layout: LoginLayout,
    component: lazy(() => import("src/component/Logo")),
  },

  {
    exact: true,
    path: "/forget-password",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/Verify-otp",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/VerifyOtp.js")),
  },
  {
    exact: true,
    path: "/instrauctions",
    // guard:true,
    component: lazy(() => import("src/views/auth/forget-password-link/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    // guard:true,{n}
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/add-admin",
    // guard:true,
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Admin/Index")),
  },
  {
    exact: true,
    path: "/stackingDetail",
    // guard:true,
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/component/StakingDetails")),
  },
  {
    exact: true,
    path: "/edit-partner",
    // guard:true,
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Admin/AddPartner")),
  },
  {
    exact: true,
    path: "/view-partner",
    // guard:true,
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Admin/AddPartner")),
  },
  {
    exact: true,
    path: "/addNewPartner",
    // guard:true,
    guard: true,

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Admin/AddNewPartner")
    ),
  },
  {
    exact: true,
    path: "/pressMedia",
    // guard:true,
    guard: true,

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Press&Media/index")
    ),
  },
  {
    exact: true,
    path: "/add-media",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Press&Media/mediaAdd")
    ),
  },
  {
    exact: true,
    path: "/view-media",
    // guard:true,
    guard: true,

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Press&Media/ViewMedia.js")
    ),
  },
  {
    exact: true,
    path: "/edit-media",
    // guard:true,
    guard: true,

    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Press&Media/EditMedia.js")
    ),
  },
  {
    exact: true,
    path: "/AddPartner",
    // guard:true,
    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Admin/AddPartner")),
  },

  {
    exact: true,
    path: "/ViewUser",

    guard: true,

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/User/ViewUser")),
  },

  {
    exact: true,
    path: "/static-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/Static")
    ),
  },
  {
    exact: true,
    path: "/Faq-manage",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/FaqAccordian")
    ),
  },
  {
    exact: true,
    path: "/edit-faq",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/EditFaq")
    ),
  },
  {
    exact: true,
    path: "/team-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TeamManagement")
    ),
  },
  {
    exact: true,
    path: "/Faq-manage",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/PrivacyManage")
    ),
  },
  {
    exact: true,
    path: "/add-team",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AddNewTeam")
    ),
  },
  {
    exact: true,
    path: "/view-team",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/ViewTeam")
    ),
  },
  {
    exact: true,
    path: "/edit-team",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/Edit-team.js")
    ),
  },

  {
    exact: true,
    path: "/social-links",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/SocialLinks")
    ),
  },
  {
    exact: true,
    path: "/addFAQ",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AddFAQ")
    ),
  },
  {
    exact: true,
    path: "/edit-links",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/SocialLinksEdit")
    ),
  },
  {
    exact: true,
    path: "/edit-teamData",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/SocialLinksEdit")
    ),
  },

  {
    exact: true,
    path: "/view-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/About")
    ),
  },

  {
    exact: true,
    path: "/view-announcements",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/Announcements")
    ),
  },

  {
    exact: true,
    path: "/edit-announcements",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AnnouncementsEdit")
    ),
  },

  {
    exact: true,
    path: "/edit-content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AboutEdit")
    ),
  },

  {
    exact: true,
    path: "/view-terms",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TermsCondition")
    ),
  },

  {
    exact: true,
    path: "/edit-terms",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TermsConditionEdit")
    ),
  },

  {
    exact: true,
    path: "/policy",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/PrivacyPolicy")
    ),
  },

  {
    exact: true,
    path: "/edit-policy",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/PrivacyPolicyEdit")
    ),
  },

  {
    exact: true,
    path: "/view-terms",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TermsCondition")
    ),
  },

  {
    exact: true,
    guard: true,

    path: "/terms-condition",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TermsAndConditions")),
  },
  {
    exact: true,
    guard: true,

    path: "/terms-services",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/TermsAndConditions/TermsServices")
    ),
  },
  {
    exact: true,
    guard: true,

    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
