import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    // height: "100vh",
    overflowX: "auto",
    background: theme.palette.background.dark1,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      overflow: "unset",
    },
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {},
  },
  box: {
    background: theme.palette.background.lightblack,

    //boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px',
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "50px",
    },
  },
  centro: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  opener: {
    // [theme.breakpoints.down("lg")]: {
    //   display: "none",
    // },
    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   display: "block",
    // },
    // [theme.breakpoints.only("xs")]: {
    //   display: "block",
    // },
    "@media(min-width:1280px)": {
      display: "none",
    },
  },
  headBox: {
    "& .shape1": {
      position: "absolute",
      left: "0px",
      zIndex: "0",
      bottom: "0px ",
      width: "177px",
      [theme.breakpoints.down("md")]: {
        width: "80px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& .shape3": {
      right: "0px",
      width: "157px",
      zIndex: "1",
      position: "absolute",
      top: "0",
      [theme.breakpoints.down("md")]: {
        top: "-17px",
        width: "80px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  logBox: {
    // padding:"150px 20px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "50px 60px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "60px 40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "70px 20px",
    },
  },
  loginBox: {
    background: "url('./images/background.png')",
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& h1": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
      },
    },
  },
  gridView: {
    "@media(max-width:600px)": {
      display: "none",
    },
  },
  imgBox: {
    maxWidth: "160px",
    margin: "auto",
    marginBottom: "10px",
    "@media(max-width:600px)": {
      maxWidth: "120px",
    },
  },
  leftImg: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "150px",
      marginTop: "10px",
    },
  },
  PdTp: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mainbox: {
    boxShadow: "0px 30px 30px rgba(0, 0, 0, 0.3)",
    backgroundColor: "#302F35",
    backdropFilter: "blur(42px)",
    backgroundImage: "url(/images/background.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  content: {
    // height: "100vh",

    overflowX: "auto",
    background: "#000",
    color: "#fff",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={classes.headBox}>
        <img
          src="images/LeftImage.png"
          alt="Top Left Arrow"
          className="shape1 moveTop"
        />
        <img
          src="images/RightImage.png"
          alt="Top Right Arrow"
          className="shape3 moveLeft"
        />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={5} className={classes.left}>
          <Box
            className={classes.mainbox}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Box className="signupmain">
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="h5"
                  style={{ fontSize: "30px", color: "white" }}
                >
                  Welcome Back
                </Typography>
              </Box>{" "}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ cursor: "pointer" }}
              >
                <img
                  src="/images/logo.png"
                  style={{ width: "250px", margin: "20px 0" }}
                  alt=""
                  onClick={() => history.push("/")}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={7} className={classes.PdTp}>
          <Container>
            <Box className={classes.logBox}>
              <Box className={classes.imgBox}>
                <img
                  className={classes.opener}
                  src="./images/move2Earn/logo.png"
                  alt="LOGO for MOVE2EARN"
                  width="100%"
                />
              </Box>

              <Container>
                <Box
                  className={classes.content}
                  style={{ margin: "auto", maxWidth: "500px" }}
                >
                  {children}
                </Box>
              </Container>
            </Box>
          </Container>
        </Grid>
      </Grid>
      {/* <Box className={classes.headBox}>
        {/* <img
        src="images/LeftImage.png"
        alt="Top Left Arrow"
        className="shape1 moveTop"
      />
      <img
        src="images/RightImage.png"
        alt="Top Right Arrow"
        className="shape3 moveLeft"
      /> */}
      {/* <Box>
          <Grid container alignItems="center">
            <Container maxWidth="lg">
              <Grid container>
                {/* <Box className="leftShade"></Box> */}
      {/* <Grid
                  item
                  lg={5}
                  sm={5}
                  md={5}
                  xs={12}
                  className={classes.gridView}
                >
                  <Box className="loginBox" className={classes.loginBox}>
                    <Box>
                      <Typography variant="h1">Welcome Back</Typography>
                      <img
                        src="./images/move2Earn/logo.png"
                        alt="LOGO for MOVE2EARN"
                        width="100%"
                        className={classes.leftImg}
                      />
                    </Box>
                  </Box>
                </Grid> */}
      {/* <Grid item lg={7} md={7} sm={7} xs={12}>
                  <Box>
                    <Box className={classes.logBox}>
                      <Box className={classes.imgBox}>
                        <img
                          className={classes.opener}
                          src="./images/move2Earn/logo.png"
                          alt="LOGO for MOVE2EARN"
                          width="100%"
                        />
                      </Box>

                      <Box className={classes.content}>{children}</Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Box> */}
      {/* </Box> */}
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
