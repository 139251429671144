import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "src/APIconfig/ApiConfig";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { calculateTimeLeft } from "src/utils/index";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}
console.log("jkshdfjsdf", window.sessionStorage.getItem("token"));
export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [searchToken, setSearchToken] = useState("");
  const [theme, setTheme] = useState(true);
  const [timeLeft, setTimeLeft] = useState();
  const token = window.sessionStorage.getItem("token");
  const [endTime, setEndtime] = useState();

  console.log("sjfkjasdfh", isLogin);
  const getProfileHandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.profile,
        headers: {
          token: token,
        },
      });
      if (res.data.responseCode === 200) {
        setUserData(res.data.result);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    if (token) {
      getProfileHandler(token);
    }
  }, [token]);
  let data = {
    userLoggedIn: isLogin,
    userData,
    searchToken,
    theme,
    timeLeft,
    setEndtime,
    setTimeLeft,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    setTheme: (data) => setTheme(data),
    setSearchToken: (data) => setSearchToken(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },

    getProfileHandler: (data) => getProfileHandler(data),
  };
  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
