// let baseurl = "http://182.72.203.250:1930";

// let baseurl = "https://node-move2earn.mobiloitte.com";
let baseurl = "https://nodepune-move2earn.mobiloitte.com"; //pune

// let baseurl = "https://blockchain-tool.mobiloitte.com";

let user = `${baseurl}/api/v1/user`;
let admin = `${baseurl}/api/v1/admin`;
let contract = `${baseurl}/api/v1/contract`;
let media = `${baseurl}/api/v1/media`;
let partner = `${baseurl}/api/v1/partner`;
let stake = `${baseurl}/api/v1/stake`;
let team = `${baseurl}/api/v1/team`;
let faq = `${baseurl}/api/v1/faq`;
let nft = `${baseurl}/api/v1/nft`;
let statics = `${baseurl}/api/v1/static`;
let staticLink = `${baseurl}/api/v1/staticLink`;
let game = `${baseurl}/api/v1/game`;

const apiConfig = {
  //USER
  login: `${admin}/loginAdmin`,
  forgotPassword: `${admin}/forgotPassword`,
  listUser: `${admin}/listUser`,
  userLogHistoryadmin: `${admin}/userLogHistory`,

  Verify: `${user}/verifyOTP`,
  resendOTP: `${user}/resendOTP`,

  reset: `${admin}/resetPassword`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  profile: `${admin}/adminProfile`,
  stake: `${stake}/stakeList`,
  stakeView: `${stake}/viewStake/{_id}`,
  // viewUser: `${admin}/viewUser`,
  Verify: `${user}/verifyOTP`,
  team: `${team}/team`,
  teamView: `${team}/team/`,
  viewUser: `${admin}/viewUser/`,
  deleteUser: `${admin}/deleteUser`,
  totalPoints: `${admin}/totalPoints`,
  listAllNft: `${admin}/listAllNft`,
  listNFT: `${admin}/listNFT`,

  Verify: `${user}/verifyOTP`,
  faq: `${faq}/faq`,
  // listAllNft: `${nft}/listAllNft`,
  viewNFT: `${nft}/viewNFT/`,
  listStatic: `${statics}/static`,
  //Partner Mgmt
  partnerList: `${partner}/partner`,
  blockPartnerList: `${partner}/partner/`,

  //CONTRACT
  getPolygonContractDetails: `${contract}/getPolygonContractDetails`,
  //Press Media
  mediaList: `${media}/media`,
  blockmediaList: `${media}/media/`,
  blockTeamList: `${team}/team/`,
  teamAdd: `${team}/team/`,
  //Static Link
  staticLinK: `${staticLink}/staticLink`,
  //DASHBOARD
  dashboardData: `${admin}/dashboard`,
  gameHistoryFilteradmin: `${admin}/gameHistoryFilter`,

  // static
  static: `${statics}/static`,
  staticList: `${statics}/static`,

  //game

  gameHistoryFilter: `${game}/gameHistoryFilter`,
};

export default apiConfig;
